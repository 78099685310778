/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
body .pi {  font-size: 1em; font-weight: bolder;}
body .ui-messages .ui-messages-icon { font-size: 1em; font-weight: 1000; display: flex;  align-items: center; }
.ui-tr {  font-family: Calibri, "Helvetica Neue", sans-serif;  font-size: 14px; }
.ng-valid[required], .ng-valid.required  { border-left: 3px solid #42A948; }
.ng-invalid:not(form)  {  border-left: 3px solid #c94442; }

.box2 {
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 2px;
  width: 65%;
  vertical-align: middle;
}
.bl-col1{
  width: 130px;
  font-weight: bold;
  vertical-align: top;
}

.cap-hdr {
  font-size: 20px;
  color: white;
  background-color: #218a94;
  padding: 6px 50px 6px 50px;
  display: inline-block;
  border-radius: 4px;
}

.ellipsis {
  vertical-align: top;
  display: inline-block;
  text-overflow: ellipsis;
 /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.ellipsis:hover {
  overflow: visible;
  white-space: pre-wrap;
}

.sugg-ico {
  float: right;
  margin-right: 9px;
  margin-top: -26px;
  position: relative;
  opacity: .4;
  color: grey;
  }

  .r-ico {
    float: right;
    margin-right: 8px;
    margin-top: -26px;
    position: relative;
  }




